<!--
 * @Descripttion: 
 * @version: v1.0.0
 * @Author: 庄志莹
 * @Date: 2020-11-17 09:20:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-09 11:30:35
-->

<template>
  <div class="loginContainer">
    <div class="loginBox">
      <div class="loginBoxL">
        <div class="logo"><img src="@/assets/img/logo.png" alt="" /></div>
        <span class="texOne">智慧校园云平台</span>
        <span class="texTwo">优秀的统一集控管理平台</span>
      </div>
      <div class="loginBoxR">
        <h3>扫码登录</h3>
        <div class="qrcode-container-total d-f a-c j-c">
          <div class="qrcode-container" ref="qrCodeContainer"></div>
          <div class="qrcode-phone">
            <img src="@/assets/img/scan-phone.png" alt="" />
          </div>
        </div>
        <div class="dust d-f f-d a-c j-c" v-if="needUpdate">
          <h4>二维码已失效</h4>
          <a-button type="primary" @click="update"> 点击刷新 </a-button>
        </div>
        <div class="dust d-f f-d a-c j-c" v-if="hasSuccess">
          <h4>登录成功，跳转中…</h4>
        </div>
        <p>请使用 微信扫一扫 扫描二维码登录</p>
        <a class="ReturnLogin" @click="goLogin">返回登录</a>
      </div>
    </div>

    <!-- 底部信息 -->
    <!-- <span class="layer"
      >©2021 XNIU 苏公网安备 32011502011289号 苏ICP备2020065401号
      江苏小牛电子科技有限公司</span
    > -->
    <!-- 底部信息 -->
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      uuid: "",
      timer: null,
      baseUrl: process.env.VUE_APP_BASEURL,
      needUpdate: false,
      hasSuccess: false,
      referrer: "",
    };
  },
  methods: {
    //返回登录
    goLogin() {
      this.$router.push("/login");
    },
    //点击刷新
    update() {
      this.$refs.qrCodeContainer.innerHTML = "";
      this.needUpdate = false;
      this.getUuid();
      this.timer = setInterval(() => {
        this.checkUuid();
      }, 1000);
    },
    //生成二维码
    bindQRCode() {
      new QRCode(this.$refs.qrCodeContainer, {
        text: this.baseUrl + "acl/wx/neutral/wxScan/?uuid=" + this.uuid,
        width: 140,
        height: 140,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    //得到uuid
    getUuid() {
      this.$api.getUUID().then((res) => {
        if (res.success) {
          this.uuid = res.data.uuid;
          this.bindQRCode();
        }
      });
    },
    //检查uuid状态
    checkUuid() {
      this.$api.checkUUID({ UUID: this.uuid }).then((res) => {
        console.log(res.data.ret);
        if (res.data.ret === "3") {
          clearInterval(this.timer);
          this.hasSuccess = true;
          this.$ls.set("token", res.data.token);
          this.setCookie("token", res.data.token, 1);
          this.$api.getUserInfo().then((result) => {
            if (result.success) {
              this.$ls.set("userInfo", result.data);
              //路由跳转
              this.$message.success("登录成功");
              if (this.referrer.includes("https://resource.jsxniu.com")) {
                window.open("https://resource.jsxniu.com", "_self");
              } else {
                this.$router.push("/main");
              }
            }
          });
        } else if (res.data.ret === "2") {
          clearInterval(this.timer);
          this.needUpdate = true;
        }
      });
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString()) +
        ";path=/;domain=jsxniu.com";
    },
  },
  created() {
    let referrer = document.referrer;
    this.referrer = referrer;
  },
  mounted() {
    this.getUuid();
    this.timer = setInterval(() => {
      this.checkUuid();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  //在页面离开时做的操作
  beforeRouteLeave(to, from, next) {
    this.$destroy();
    next();
  },
};
</script>

<style lang="scss" scoped>
.loginContainer {
  width: 100%;
  height: 100%;
  min-height: 1440px;
  min-height: 900px;
  position: absolute;
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
  background-repeat: no-repeat;

  .loginBox {
    width: 980px;
    height: 613px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loginBoxL {
    float: left;
    width: 535px;
    height: 613px;
    background-image: url("~@/assets/img/loginBoxL.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    .logo {
      position: relative;
      top: 38px;
      left: 48px;
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .texOne {
      position: absolute;
      top: 80px;
      left: 50px;
      font-family: PingFang SC;
      font-weight: 600;
      line-height: 24px;
      font-size: 24px;
      color: #06628d;
      opacity: 1;
    }
    .texTwo {
      position: absolute;
      top: 120px;
      left: 50px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 12px;
      color: #06628d;
      opacity: 1;
    }
  }
  .loginBoxR {
    position: relative;
    float: left;
    width: 445px;
    height: 613px;
    background-image: url("~@/assets/img/loginBoxR.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      width: 96px;
      height: 33px;
      font-size: 24px;
      font-family: "PingFang SC";
      font-weight: bold;
      line-height: 22px;
      color: rgba(6, 98, 141, 0.85);
      opacity: 1;
      position: absolute;
      top: 153px;
      margin-bottom: 0;
    }
    .dust {
      width: 160px;
      height: 160px;
      position: absolute;
      top: 198px;
      background-color: rgba(255, 255, 255, 0.93);
    }
    .qrcode-container-total {
      width: 140px;
      height: 140px;
      position: absolute;
      top: 208px;
      .qrcode-container {
        position: absolute;
        left: 0px;
        transition: left 1s;
      }
      .qrcode-phone {
        width: 167px;
        height: 177px;
        position: absolute;
        left: 80px;
        display: none;
        transition: display 1s;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .qrcode-container-total:hover .qrcode-container {
      left: -80px;
    }
    .qrcode-container-total:hover .qrcode-phone {
      display: block;
    }
    p {
      position: absolute;
      bottom: 180px;
      width: 251px;
      height: 22px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      opacity: 1;
    }
    .ReturnLogin {
      color: #009fe8;
      position: absolute;
      bottom: 147px;
    }
  }
}
.layer {
  position: absolute;
  left: 50%;
  bottom: 4%;
  transform: translateX(-50%);
  font-size: 12px;
  font-size-adjust: none;
  color: #ffffffff;
}
</style>
